@font-face {
  font-family: 'FontAwesome';
  src: url('../fontawesome/webfonts/fa-regular-400.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url("../fonts/MaterialIcons/MaterialIcons-Regular.ttf") format('truetype');
}

.material-icons {
 font-family: 'Material Icons';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;  /* Preferred icon size */
 display: inline-block;
 line-height: 1;
 text-transform: none;
 letter-spacing: normal;
 word-wrap: normal;
 white-space: nowrap;
 direction: ltr;

 /* Support for all WebKit browsers. */
 -webkit-font-smoothing: antialiased;
 /* Support for Safari and Chrome. */
 text-rendering: optimizeLegibility;

 /* Support for Firefox. */
 -moz-osx-font-smoothing: grayscale;

 /* Support for IE. */
 font-feature-settings: 'liga';
}

// Variables
@import 'variables';

// Material dash
@import 'material-dashboard';

/* Material dashboard modifications */ 
.btn-primary:hover{
  background-color: #0069d9 !important;

}

/* Utilities */
.font-weight-thick{
    font-weight: 600 !important;
}
.w-max-100{
    max-width: 100px;
}

/* CKEditor Styles */
.cke {
  border: none !important;
  border-bottom: 1px solid black;
}

.cke_inner {
  border: none;
  background: transparent;
}
  
.cke_bottom{
  background-color: transparent !important;
}

.kt-hover:hover {
  opacity: 0.7;
  transition: 0.5s all;
}